import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-print-submission-footer',
  standalone: true,
  imports: [],
  templateUrl: './print-submission-footer.component.html',
  styleUrl: './print-submission-footer.component.scss',
})
export class PrintSubmissionFooterComponent {
  @Input() public year: string;
  @Input() public granteeName: string;
  @Input() public pageNumber: string;
}
