<div *ngIf="loading$ | async" class="loader-container">
  <div class="spinner" role="progressbar">
    <svg
      class="circular"
      preserveAspectRatio="xMidYMid meet"
      focusable="false"
      viewBox="0 0 100 100"
    >
      <circle cx="50%" cy="50%" r="45" class="path"></circle>
    </svg>
  </div>
  <div class="progressring-group">
    <div class="progressring" role="progressbar">
      <div class="circle"></div>
      <div class="circle"></div>
      <div class="circle"></div>
      <div class="circle"></div>
      <div class="circle"></div>
    </div>
  </div>
  <div class="label">Generating report...</div>
</div>
<ng-container *ngIf="(loading$ | async) === false">
  <div class="print-btn-wrap">
    <button class="print-btn" mat-button onclick="print()">
      <mat-icon
        class="material-icons-outlined"
        tabindex="0"
        aria-label="info icon"
        >print</mat-icon
      >Print
    </button>
  </div>
  <div class="animate__animated animate__fadeIn print-layout-container">
    <div class="print-layout-inner-container">
      <div class="print-layout">
        <img
          src="../../../../assets/oash-logo-bw.svg"
          class="print-logo"
          alt="OASH Office of Population Affairs logo"
        />
        <div class="print-title">
          <p class="print-title-intro">
            {{ dataReportingPeriodYear }} FPAR<br />
            Reporting Period<br />
            Summary for
          </p>
          <p class="print-title-grant">{{ fparReportData.granteeName }}</p>
        </div>
        <div class="print-project-director">
          <p class="print-project-director-label">Title X Project Director</p>
          <p class="print-project-director-name">
            {{ grantCoverSheetDirector.grProjDirFirst }}
            {{ grantCoverSheetDirector.grProjDirLast }}
          </p>
        </div>
        <div class="print-contact-person">
          <p class="print-contact-person-label">Grantee Contact Person</p>
          <p class="print-contact-person-name">
            {{ grantCoverSheetContact.grContactFirst }}
            {{ grantCoverSheetContact.grContactLast }}
          </p>
        </div>
        <table class="print-cover-sheet-info">
          <tbody>
            <tr>
              <td>FPAR number</td>
              <td>{{ fparReportData.grFparNumber }}</td>
            </tr>
            <tr>
              <td>Accepted date</td>
              <td>{{ acceptedDate }}</td>
            </tr>
            <tr>
              <td>Grant reporting period</td>
              <td>{{ fparReportData.reportingPeriodName }}</td>
            </tr>
            <tr>
              <td>Number of sites supported</td>
              <td>{{ grantNumberofSites.titlexSites }}</td>
            </tr>
          </tbody>
        </table>
        <div class="print-last-updated">
          <p class="print-project-director-label">Report generated:</p>
          <p class="print-project-director-date">{{ today }}</p>
        </div>
      </div>
      <div class="page-break"></div>
      <div class="print-layout">
        <p class="print-toc-header">Table of Contents</p>
        <table class="print-toc">
          <tbody>
            <tr>
              <td>1</td>
              <td>
                Unduplicated Number of Family Planning Users by Age Group and
                Sex
              </td>
            </tr>
            <tr>
              <td>2</td>
              <td>
                Unduplicated Number of Female Family Planning Users by Race and
                Ethnicity
              </td>
            </tr>
            <tr>
              <td>3</td>
              <td>
                Unduplicated Number of Male Family Planning Users by Race and
                Ethnicity
              </td>
            </tr>
            <tr>
              <td>4</td>
              <td>
                Unduplicated Number of Family Planning Users by Income Level
              </td>
            </tr>
            <tr>
              <td>5</td>
              <td>
                Unduplicated Number of Family Planning Users by Principal Health
                Insurance Coverage Status
              </td>
            </tr>
            <tr>
              <td>6</td>
              <td>
                Unduplicated Number of Family Planning Users with Limited
                English Proficiency (LEP)
              </td>
            </tr>
            <tr>
              <td>7</td>
              <td>
                Unduplicated Number of Female Family Planning Users by Primary
                Method and Age Group
              </td>
            </tr>
            <tr>
              <td>8</td>
              <td>
                Unduplicated Number of Male Family Planning Users by Primary
                Method and Age Group
              </td>
            </tr>
            <tr>
              <td>9</td>
              <td>Cervical Cancer Screening Activities</td>
            </tr>
            <tr>
              <td>10</td>
              <td>Clinical Breast Exams and Referrals</td>
            </tr>
            <tr>
              <td>11</td>
              <td>
                Unduplicated Number of Family Planning Users Tested for
                Chlamydia by Age Group and Sex
              </td>
            </tr>
            <tr>
              <td>12</td>
              <td>
                Number of Tests for Gonorrhea, Syphilis, and HIV and Number of
                Positive Confidential HIV Tests
              </td>
            </tr>
            <tr>
              <td>13</td>
              <td>
                Number of Full-Time Equivalent Clinical Services Providers and
                Family Planning Encounters by Type of Provider
              </td>
            </tr>
            <tr>
              <td>14</td>
              <td>Revenue Report</td>
            </tr>
            <tr>
              <td>15</td>
              <td>Appendix</td>
            </tr>
          </tbody>
        </table>
        <app-print-submission-footer
          class="print-footer"
          [year]="dataReportingPeriodYear"
          [granteeName]="fparReportData.granteeName"
        ></app-print-submission-footer>
      </div>
      <div class="page-break"></div>
      <div class="print-layout">
        <ol class="print-numbered-heading-1">
          <li class="print-numbered-heading-1-item">
            {{ fparReportData.fparTables[0].tableName }}
          </li>
          <li class="print-numbered-heading-1-item-title">
            {{ fparReportData.fparTables[0].tableTitle }}
          </li>
        </ol>

        <table class="print-table">
          <thead>
            <tr>
              <th></th>
              <th></th>
              <th scope="col">Female Users</th>
              <th scope="col">Male Users</th>
              <th scope="col">Total</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th rowspan="10" scope="row" style="font-weight: bold">
                Age Group
              </th>
            </tr>
            <tr>
              <td>{{ fparReportData.fparTables[0].rows[0].rowLabel }}</td>
              <td>{{ fparReportData.fparTables[0].rows[0].female }}</td>
              <td>{{ fparReportData.fparTables[0].rows[0].male }}</td>
              <td>{{ rowTotals[0][0] }}</td>
            </tr>
            <tr>
              <td>{{ fparReportData.fparTables[0].rows[1].rowLabel }}</td>
              <td>{{ fparReportData.fparTables[0].rows[1].female }}</td>
              <td>{{ fparReportData.fparTables[0].rows[1].male }}</td>
              <td>{{ rowTotals[0][1] }}</td>
            </tr>

            <tr>
              <td>{{ fparReportData.fparTables[0].rows[2].rowLabel }}</td>
              <td>{{ fparReportData.fparTables[0].rows[2].female }}</td>
              <td>{{ fparReportData.fparTables[0].rows[2].male }}</td>
              <td>{{ rowTotals[0][2] }}</td>
            </tr>
            <tr>
              <td>{{ fparReportData.fparTables[0].rows[3].rowLabel }}</td>
              <td>{{ fparReportData.fparTables[0].rows[3].female }}</td>
              <td>{{ fparReportData.fparTables[0].rows[3].male }}</td>
              <td>{{ rowTotals[0][3] }}</td>
            </tr>
            <tr>
              <td>{{ fparReportData.fparTables[0].rows[4].rowLabel }}</td>
              <td>{{ fparReportData.fparTables[0].rows[4].female }}</td>
              <td>{{ fparReportData.fparTables[0].rows[4].male }}</td>
              <td>{{ rowTotals[0][4] }}</td>
            </tr>
            <tr>
              <td>{{ fparReportData.fparTables[0].rows[5].rowLabel }}</td>
              <td>{{ fparReportData.fparTables[0].rows[5].female }}</td>
              <td>{{ fparReportData.fparTables[0].rows[5].male }}</td>
              <td>{{ rowTotals[0][5] }}</td>
            </tr>
            <tr>
              <td>{{ fparReportData.fparTables[0].rows[6].rowLabel }}</td>
              <td>{{ fparReportData.fparTables[0].rows[6].female }}</td>
              <td>{{ fparReportData.fparTables[0].rows[6].male }}</td>
              <td>{{ rowTotals[0][6] }}</td>
            </tr>
            <tr>
              <td>{{ fparReportData.fparTables[0].rows[7].rowLabel }}</td>
              <td>{{ fparReportData.fparTables[0].rows[7].female }}</td>
              <td>{{ fparReportData.fparTables[0].rows[7].male }}</td>
              <td>{{ rowTotals[0][7] }}</td>
            </tr>
            <tr>
              <td>{{ fparReportData.fparTables[0].rows[8].rowLabel }}</td>
              <td>{{ fparReportData.fparTables[0].rows[8].female }}</td>
              <td>{{ fparReportData.fparTables[0].rows[8].male }}</td>
              <td>{{ rowTotals[0][8] }}</td>
            </tr>
            <tr>
              <th>Total</th>
              <td></td>
              <td style="font-weight: bold; text-align: right">
                {{ columnTotals[0] }}
              </td>
              <td style="font-weight: bold; text-align: right">
                {{ columnTotals[1] }}
              </td>
              <td style="font-weight: bold; text-align: right">
                {{ totalTotals[0] }}
              </td>
            </tr>
          </tbody>
        </table>
        <app-print-submission-footer
          class="print-footer"
          [year]="dataReportingPeriodYear"
          [granteeName]="fparReportData.granteeName"
          pageNumber="1"
        ></app-print-submission-footer>
      </div>
      <div class="page-break"></div>
      <div class="print-layout">
        <ol class="print-numbered-heading-1">
          <li class="print-numbered-heading-1-item">
            {{ fparReportData.fparTables[1].tableName }}
          </li>
          <li class="print-numbered-heading-1-item-title">
            Unduplicated Number of Female Family Planning Users by Race and
            Ethnicity
          </li>
        </ol>

        <table class="print-table">
          <thead>
            <tr>
              <th></th>
              <th></th>
              <th scope="col">Hispanic<br />or Latino</th>
              <th scope="col">Not Hispanic<br />or Latino</th>
              <th scope="col">Unknown/Not<br />Reported</th>
              <th scope="col">Total</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th rowspan="8" scope="row" style="font-weight: bold">Race</th>
            </tr>
            <tr>
              <td>American Indian or Alaska Native</td>
              <td>{{ fparReportData.fparTables[1].rows[0].hispanic }}</td>
              <td>{{ fparReportData.fparTables[1].rows[0].notHispanic }}</td>
              <td>{{ fparReportData.fparTables[1].rows[0].unknown }}</td>
              <td>{{ rowTotals[1][0] }}</td>
            </tr>
            <tr>
              <td>Asian</td>
              <td>{{ fparReportData.fparTables[1].rows[1].hispanic }}</td>
              <td>{{ fparReportData.fparTables[1].rows[1].notHispanic }}</td>
              <td>{{ fparReportData.fparTables[1].rows[1].unknown }}</td>
              <td>{{ rowTotals[1][1] }}</td>
            </tr>

            <tr>
              <td>Black or African American</td>
              <td>{{ fparReportData.fparTables[1].rows[2].hispanic }}</td>
              <td>{{ fparReportData.fparTables[1].rows[2].notHispanic }}</td>
              <td>{{ fparReportData.fparTables[1].rows[2].unknown }}</td>
              <td>{{ rowTotals[1][2] }}</td>
            </tr>
            <tr>
              <td>Native Hawaiian or Other Pacific Islander</td>
              <td>{{ fparReportData.fparTables[1].rows[3].hispanic }}</td>
              <td>{{ fparReportData.fparTables[1].rows[3].notHispanic }}</td>
              <td>{{ fparReportData.fparTables[1].rows[3].unknown }}</td>
              <td>{{ rowTotals[1][3] }}</td>
            </tr>
            <tr>
              <td>White</td>
              <td>{{ fparReportData.fparTables[1].rows[4].hispanic }}</td>
              <td>{{ fparReportData.fparTables[1].rows[4].notHispanic }}</td>
              <td>{{ fparReportData.fparTables[1].rows[4].unknown }}</td>
              <td>{{ rowTotals[1][4] }}</td>
            </tr>
            <tr>
              <td>More than one race</td>
              <td>{{ fparReportData.fparTables[1].rows[5].hispanic }}</td>
              <td>{{ fparReportData.fparTables[1].rows[5].notHispanic }}</td>
              <td>{{ fparReportData.fparTables[1].rows[5].unknown }}</td>
              <td>{{ rowTotals[1][5] }}</td>
            </tr>
            <tr>
              <td>Unknown/not reported</td>
              <td>{{ fparReportData.fparTables[1].rows[6].hispanic }}</td>
              <td>{{ fparReportData.fparTables[1].rows[6].notHispanic }}</td>
              <td>{{ fparReportData.fparTables[1].rows[6].unknown }}</td>
              <td>{{ rowTotals[1][6] }}</td>
            </tr>
            <tr>
              <th>Total</th>
              <td></td>
              <td style="font-weight: bold; text-align: right">
                {{ columnTotals[2] }}
              </td>
              <td style="font-weight: bold; text-align: right">
                {{ columnTotals[3] }}
              </td>
              <td style="font-weight: bold; text-align: right">
                {{ columnTotals[4] }}
              </td>
              <td style="font-weight: bold; text-align: right">
                {{ totalTotals[1] }}
              </td>
            </tr>
          </tbody>
        </table>
        <app-print-submission-footer
          class="print-footer"
          [year]="dataReportingPeriodYear"
          [granteeName]="fparReportData.granteeName"
          pageNumber="2"
        ></app-print-submission-footer>
      </div>
      <div class="page-break"></div>
      <div class="print-layout">
        <ol class="print-numbered-heading-1">
          <li class="print-numbered-heading-1-item">
            {{ fparReportData.fparTables[2].tableName }}
          </li>
          <li class="print-numbered-heading-1-item-title">
            Unduplicated Number of Male Family Planning Users by Race and
            Ethnicity
          </li>
        </ol>

        <table class="print-table">
          <thead>
            <tr>
              <th></th>
              <th></th>
              <th scope="col">Hispanic<br />or Latino</th>
              <th scope="col">Not Hispanic<br />or Latino</th>
              <th scope="col">Unknown/Not<br />Reported</th>
              <th scope="col">Total</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th rowspan="8" scope="row" style="font-weight: bold">Race</th>
            </tr>
            <tr>
              <td>American Indian or Alaska Native</td>
              <td>{{ fparReportData.fparTables[2].rows[0].hispanic }}</td>
              <td>{{ fparReportData.fparTables[2].rows[0].notHispanic }}</td>
              <td>{{ fparReportData.fparTables[2].rows[0].unknown }}</td>
              <td>{{ rowTotals[2][0] }}</td>
            </tr>
            <tr>
              <td>Asian</td>
              <td>{{ fparReportData.fparTables[2].rows[1].hispanic }}</td>
              <td>{{ fparReportData.fparTables[2].rows[1].notHispanic }}</td>
              <td>{{ fparReportData.fparTables[2].rows[1].unknown }}</td>
              <td>{{ rowTotals[2][1] }}</td>
            </tr>

            <tr>
              <td>Black or African American</td>
              <td>{{ fparReportData.fparTables[2].rows[2].hispanic }}</td>
              <td>{{ fparReportData.fparTables[2].rows[2].notHispanic }}</td>
              <td>{{ fparReportData.fparTables[2].rows[2].unknown }}</td>
              <td>{{ rowTotals[2][2] }}</td>
            </tr>
            <tr>
              <td>Native Hawaiian or Other Pacific Islander</td>
              <td>{{ fparReportData.fparTables[2].rows[3].hispanic }}</td>
              <td>{{ fparReportData.fparTables[2].rows[3].notHispanic }}</td>
              <td>{{ fparReportData.fparTables[2].rows[3].unknown }}</td>
              <td>{{ rowTotals[2][3] }}</td>
            </tr>
            <tr>
              <td>White</td>
              <td>{{ fparReportData.fparTables[2].rows[4].hispanic }}</td>
              <td>{{ fparReportData.fparTables[2].rows[4].notHispanic }}</td>
              <td>{{ fparReportData.fparTables[2].rows[4].unknown }}</td>
              <td>{{ rowTotals[2][4] }}</td>
            </tr>
            <tr>
              <td>More than one race</td>
              <td>{{ fparReportData.fparTables[2].rows[5].hispanic }}</td>
              <td>{{ fparReportData.fparTables[2].rows[5].notHispanic }}</td>
              <td>{{ fparReportData.fparTables[2].rows[5].unknown }}</td>
              <td>{{ rowTotals[2][5] }}</td>
            </tr>
            <tr>
              <td>Unknown/not reported</td>
              <td>{{ fparReportData.fparTables[2].rows[6].hispanic }}</td>
              <td>{{ fparReportData.fparTables[2].rows[6].notHispanic }}</td>
              <td>{{ fparReportData.fparTables[2].rows[6].unknown }}</td>
              <td>{{ rowTotals[2][6] }}</td>
            </tr>
            <tr>
              <th>Total</th>
              <td></td>
              <td style="font-weight: bold; text-align: right">
                {{ columnTotals[5] }}
              </td>
              <td style="font-weight: bold; text-align: right">
                {{ columnTotals[6] }}
              </td>
              <td style="font-weight: bold; text-align: right">
                {{ columnTotals[7] }}
              </td>
              <td style="font-weight: bold; text-align: right">
                {{ totalTotals[2] }}
              </td>
            </tr>
          </tbody>
        </table>
        <app-print-submission-footer
          class="print-footer"
          [year]="dataReportingPeriodYear"
          [granteeName]="fparReportData.granteeName"
          pageNumber="3"
        ></app-print-submission-footer>
      </div>
      <div class="page-break"></div>
      <div class="print-layout">
        <ol class="print-numbered-heading-1">
          <li class="print-numbered-heading-1-item">
            {{ fparReportData.fparTables[3].tableName }}
          </li>
          <li class="print-numbered-heading-1-item-title">
            Unduplicated Number of Family Planning Users by Income Level
          </li>
        </ol>

        <table class="print-table">
          <thead>
            <tr>
              <th></th>
              <th></th>
              <th scope="col">Total</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th rowspan="7" scope="row" style="font-weight: bold">
                Income Level as a Percentage of the HHS Poverty Guidelines
              </th>
            </tr>
            <tr>
              <td>100% and below</td>
              <td>{{ fparReportData.fparTables[3].rows[0].value }}</td>
            </tr>
            <tr>
              <td>101% to 150%</td>
              <td>{{ fparReportData.fparTables[3].rows[1].value }}</td>
            </tr>

            <tr>
              <td>151% to 200%</td>
              <td>{{ fparReportData.fparTables[3].rows[2].value }}</td>
            </tr>
            <tr>
              <td>201% to 250%</td>
              <td>{{ fparReportData.fparTables[3].rows[3].value }}</td>
            </tr>
            <tr>
              <td>Over 250%</td>
              <td>{{ fparReportData.fparTables[3].rows[4].value }}</td>
            </tr>
            <tr>
              <td>Unknown/not reported</td>
              <td>{{ fparReportData.fparTables[3].rows[5].value }}</td>
            </tr>
            <tr>
              <th>Total</th>
              <td></td>
              <td style="font-weight: bold; text-align: right">
                {{ totalTotals[3] }}
              </td>
            </tr>
          </tbody>
        </table>
        <app-print-submission-footer
          class="print-footer"
          [year]="dataReportingPeriodYear"
          [granteeName]="fparReportData.granteeName"
          pageNumber="4"
        ></app-print-submission-footer>
      </div>
      <div class="page-break"></div>
      <div class="print-layout">
        <ol class="print-numbered-heading-1">
          <li class="print-numbered-heading-1-item">
            {{ fparReportData.fparTables[4].tableName }}
          </li>
          <li class="print-numbered-heading-1-item-title">
            Unduplicated Number of Family Planning Users by Principal Health
            Insurance Coverage Status
          </li>
        </ol>

        <table class="print-table">
          <thead>
            <tr>
              <th></th>
              <th></th>
              <th scope="col">Total</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th rowspan="5" scope="row" style="font-weight: bold">
                Principal Health Insurance Covering Primary Medical Care
              </th>
            </tr>
            <tr>
              <td>Public health insurance covering primary medical care</td>
              <td>{{ fparReportData.fparTables[4].rows[0].value }}</td>
            </tr>
            <tr>
              <td>Private health insurance covering primary medical care</td>
              <td>{{ fparReportData.fparTables[4].rows[1].value }}</td>
            </tr>

            <tr>
              <td>Uninsured (no public or private health insurance)</td>
              <td>{{ fparReportData.fparTables[4].rows[2].value }}</td>
            </tr>
            <tr>
              <td>Unknown/not reported</td>
              <td>{{ fparReportData.fparTables[4].rows[3].value }}</td>
            </tr>
            <tr>
              <th>Total</th>
              <td></td>
              <td style="font-weight: bold; text-align: right">
                {{ totalTotals[4] }}
              </td>
            </tr>
          </tbody>
        </table>
        <app-print-submission-footer
          class="print-footer"
          [year]="dataReportingPeriodYear"
          [granteeName]="fparReportData.granteeName"
          pageNumber="5"
        ></app-print-submission-footer>
      </div>
      <div class="page-break"></div>
      <div class="print-layout">
        <ol class="print-numbered-heading-1">
          <li class="print-numbered-heading-1-item">
            {{ fparReportData.fparTables[5].tableName }}
          </li>
          <li class="print-numbered-heading-1-item-title">
            Unduplicated Number of Family Planning Users with Limited English
            Proficiency (LEP)
          </li>
        </ol>

        <table class="print-table">
          <thead>
            <tr>
              <th></th>
              <th scope="col">Total</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>LEP users</td>
              <td>{{ fparReportData.fparTables[5].rows[0].value }}</td>
            </tr>
            <tr>
              <td>Not LEP users</td>
              <td>{{ fparReportData.fparTables[5].rows[1].value }}</td>
            </tr>
            <tr>
              <td>Unknown/not reported</td>
              <td>{{ fparReportData.fparTables[5].rows[2].value }}</td>
            </tr>
            <tr>
              <th>Total</th>
              <td style="font-weight: bold; text-align: right">
                {{ totalTotals[5] }}
              </td>
            </tr>
          </tbody>
        </table>
        <app-print-submission-footer
          class="print-footer"
          [year]="dataReportingPeriodYear"
          [granteeName]="fparReportData.granteeName"
          pageNumber="6"
        ></app-print-submission-footer>
      </div>
      <div class="page-break"></div>
      <div class="print-layout">
        <ol class="print-numbered-heading-1">
          <li class="print-numbered-heading-1-item">
            {{ fparReportData.fparTables[6].tableName }}
          </li>
          <li class="print-numbered-heading-1-item-title">
            Unduplicated Number of Female Family Planning Users by Primary
            Method and Age Group
          </li>
        </ol>

        <table class="print-table">
          <colgroup>
            <col style="width: 68px" />
            <col style="width: 54px" />
            <col />
            <col />
            <col />
            <col />
            <col />
            <col />
            <col />
            <col />
            <col />
            <col />
          </colgroup>
          <thead>
            <tr>
              <th></th>
              <th></th>
              <th scope="col">Under 15</th>
              <th scope="col">15 to 17</th>
              <th scope="col">18 to 19</th>
              <th scope="col">20 to 24</th>
              <th scope="col">25 to 29</th>
              <th scope="col">30 to 34</th>
              <th scope="col">35 to 39</th>
              <th scope="col">40 to 44</th>
              <th scope="col">Over 44</th>
              <th scope="col">Total</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th rowspan="16" scope="row" style="font-weight: bold">
                Primary Method
              </th>
            </tr>
            <tr>
              <td>Female sterilization</td>
              <td>{{ fparReportData.fparTables[6].rows[0].ageUnder_15 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[0].age_15To_17 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[0].age_18To_19 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[0].age_20To_24 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[0].age_25To_29 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[0].age_30To_34 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[0].age_35To_39 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[0].age_40To_44 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[0].ageOver_44 }}</td>
              <td>{{ rowTotals[6][0] }}</td>
            </tr>
            <tr>
              <td>IUD or IUS</td>
              <td>{{ fparReportData.fparTables[6].rows[1].ageUnder_15 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[1].age_15To_17 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[1].age_18To_19 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[1].age_20To_24 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[1].age_25To_29 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[1].age_30To_34 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[1].age_35To_39 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[1].age_40To_44 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[1].ageOver_44 }}</td>
              <td>{{ rowTotals[6][1] }}</td>
            </tr>
            <tr>
              <td>Hormonal implant</td>
              <td>{{ fparReportData.fparTables[6].rows[2].ageUnder_15 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[2].age_15To_17 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[2].age_18To_19 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[2].age_20To_24 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[2].age_25To_29 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[2].age_30To_34 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[2].age_35To_39 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[2].age_40To_44 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[2].ageOver_44 }}</td>
              <td>{{ rowTotals[6][2] }}</td>
            </tr>
            <tr>
              <td>1-Month hormonal injection</td>
              <td>{{ fparReportData.fparTables[6].rows[3].ageUnder_15 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[3].age_15To_17 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[3].age_18To_19 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[3].age_20To_24 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[3].age_25To_29 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[3].age_30To_34 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[3].age_35To_39 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[3].age_40To_44 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[3].ageOver_44 }}</td>
              <td>{{ rowTotals[6][3] }}</td>
            </tr>
            <tr>
              <td>3-Month hormonal injection</td>
              <td>{{ fparReportData.fparTables[6].rows[4].ageUnder_15 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[4].age_15To_17 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[4].age_18To_19 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[4].age_20To_24 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[4].age_25To_29 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[4].age_30To_34 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[4].age_35To_39 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[4].age_40To_44 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[4].ageOver_44 }}</td>
              <td>{{ rowTotals[6][4] }}</td>
            </tr>
            <tr>
              <td>Oral contraceptive</td>
              <td>{{ fparReportData.fparTables[6].rows[5].ageUnder_15 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[5].age_15To_17 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[5].age_18To_19 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[5].age_20To_24 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[5].age_25To_29 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[5].age_30To_34 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[5].age_35To_39 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[5].age_40To_44 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[5].ageOver_44 }}</td>
              <td>{{ rowTotals[6][5] }}</td>
            </tr>
            <tr>
              <td>Contraceptive patch</td>
              <td>{{ fparReportData.fparTables[6].rows[6].ageUnder_15 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[6].age_15To_17 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[6].age_18To_19 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[6].age_20To_24 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[6].age_25To_29 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[6].age_30To_34 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[6].age_35To_39 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[6].age_40To_44 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[6].ageOver_44 }}</td>
              <td>{{ rowTotals[6][6] }}</td>
            </tr>
            <tr>
              <td>Vaginal ring</td>
              <td>{{ fparReportData.fparTables[6].rows[7].ageUnder_15 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[7].age_15To_17 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[7].age_18To_19 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[7].age_20To_24 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[7].age_25To_29 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[7].age_30To_34 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[7].age_35To_39 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[7].age_40To_44 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[7].ageOver_44 }}</td>
              <td>{{ rowTotals[6][7] }}</td>
            </tr>
            <tr>
              <td>Cervical cap/ diaphragm</td>
              <td>{{ fparReportData.fparTables[6].rows[8].ageUnder_15 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[8].age_15To_17 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[8].age_18To_19 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[8].age_20To_24 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[8].age_25To_29 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[8].age_30To_34 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[8].age_35To_39 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[8].age_40To_44 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[8].ageOver_44 }}</td>
              <td>{{ rowTotals[6][8] }}</td>
            </tr>
            <tr>
              <td>Contraceptive sponge</td>
              <td>{{ fparReportData.fparTables[6].rows[9].ageUnder_15 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[9].age_15To_17 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[9].age_18To_19 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[9].age_20To_24 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[9].age_25To_29 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[9].age_30To_34 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[9].age_35To_39 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[9].age_40To_44 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[9].ageOver_44 }}</td>
              <td>{{ rowTotals[6][9] }}</td>
            </tr>
            <tr>
              <td>Female condom</td>
              <td>{{ fparReportData.fparTables[6].rows[10].ageUnder_15 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[10].age_15To_17 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[10].age_18To_19 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[10].age_20To_24 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[10].age_25To_29 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[10].age_30To_34 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[10].age_35To_39 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[10].age_40To_44 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[10].ageOver_44 }}</td>
              <td>{{ rowTotals[6][10] }}</td>
            </tr>
            <tr>
              <td>Any spermicide or non-spermicidal gel (used alone)</td>
              <td>{{ fparReportData.fparTables[6].rows[11].ageUnder_15 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[11].age_15To_17 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[11].age_18To_19 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[11].age_20To_24 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[11].age_25To_29 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[11].age_30To_34 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[11].age_35To_39 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[11].age_40To_44 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[11].ageOver_44 }}</td>
              <td>{{ rowTotals[6][11] }}</td>
            </tr>
            <tr>
              <td>FAM or LAM</td>
              <td>{{ fparReportData.fparTables[6].rows[12].ageUnder_15 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[12].age_15To_17 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[12].age_18To_19 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[12].age_20To_24 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[12].age_25To_29 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[12].age_30To_34 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[12].age_35To_39 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[12].age_40To_44 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[12].ageOver_44 }}</td>
              <td>{{ rowTotals[6][12] }}</td>
            </tr>
            <tr>
              <td>Abstinence</td>
              <td>{{ fparReportData.fparTables[6].rows[13].ageUnder_15 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[13].age_15To_17 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[13].age_18To_19 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[13].age_20To_24 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[13].age_25To_29 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[13].age_30To_34 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[13].age_35To_39 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[13].age_40To_44 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[13].ageOver_44 }}</td>
              <td>{{ rowTotals[6][13] }}</td>
            </tr>
            <tr>
              <td>Withdrawal or other</td>
              <td>{{ fparReportData.fparTables[6].rows[14].ageUnder_15 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[14].age_15To_17 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[14].age_18To_19 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[14].age_20To_24 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[14].age_25To_29 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[14].age_30To_34 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[14].age_35To_39 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[14].age_40To_44 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[14].ageOver_44 }}</td>
              <td>{{ rowTotals[6][14] }}</td>
            </tr>
            <tr>
              <th rowspan="3" scope="row" style="font-weight: bold">
                Rely on Male Method
              </th>
            </tr>
            <tr>
              <td>Vasectomy</td>
              <td>{{ fparReportData.fparTables[6].rows[15].ageUnder_15 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[15].age_15To_17 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[15].age_18To_19 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[15].age_20To_24 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[15].age_25To_29 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[15].age_30To_34 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[15].age_35To_39 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[15].age_40To_44 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[15].ageOver_44 }}</td>
              <td>{{ rowTotals[6][15] }}</td>
            </tr>
            <tr>
              <td>Male condom</td>
              <td>{{ fparReportData.fparTables[6].rows[16].ageUnder_15 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[16].age_15To_17 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[16].age_18To_19 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[16].age_20To_24 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[16].age_25To_29 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[16].age_30To_34 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[16].age_35To_39 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[16].age_40To_44 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[16].ageOver_44 }}</td>
              <td>{{ rowTotals[6][16] }}</td>
            </tr>
            <tr>
              <th rowspan="3" scope="row" style="font-weight: bold">
                No Method
              </th>
            </tr>
            <tr>
              <td>Pregnant/seeking pregnancy</td>
              <td>{{ fparReportData.fparTables[6].rows[17].ageUnder_15 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[17].age_15To_17 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[17].age_18To_19 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[17].age_20To_24 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[17].age_25To_29 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[17].age_30To_34 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[17].age_35To_39 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[17].age_40To_44 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[17].ageOver_44 }}</td>
              <td>{{ rowTotals[6][17] }}</td>
            </tr>
            <tr>
              <td>Other reason</td>
            </tr>
            <tr>
              <th colspan="2" scope="row" style="font-weight: bold">
                Unknown/Not Reported
              </th>
              <td>{{ fparReportData.fparTables[6].rows[18].ageUnder_15 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[18].age_15To_17 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[18].age_18To_19 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[18].age_20To_24 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[18].age_25To_29 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[18].age_30To_34 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[18].age_35To_39 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[18].age_40To_44 }}</td>
              <td>{{ fparReportData.fparTables[6].rows[18].ageOver_44 }}</td>
              <td>{{ rowTotals[6][18] }}</td>
            </tr>
            <tr>
              <th>Total</th>
              <td></td>
              <td style="font-weight: bold; text-align: right">
                {{ columnTotals[11] }}
              </td>
              <td style="font-weight: bold; text-align: right">
                {{ columnTotals[12] }}
              </td>
              <td style="font-weight: bold; text-align: right">
                {{ columnTotals[13] }}
              </td>
              <td style="font-weight: bold; text-align: right">
                {{ columnTotals[14] }}
              </td>
              <td style="font-weight: bold; text-align: right">
                {{ columnTotals[15] }}
              </td>
              <td style="font-weight: bold; text-align: right">
                {{ columnTotals[16] }}
              </td>
              <td style="font-weight: bold; text-align: right">
                {{ columnTotals[17] }}
              </td>
              <td style="font-weight: bold; text-align: right">
                {{ columnTotals[18] }}
              </td>
              <td style="font-weight: bold; text-align: right">
                {{ columnTotals[19] }}
              </td>
              <td style="font-weight: bold; text-align: right">
                {{ totalTotals[6] }}
              </td>
            </tr>
          </tbody>
        </table>
        <app-print-submission-footer
          class="print-footer"
          [year]="dataReportingPeriodYear"
          [granteeName]="fparReportData.granteeName"
          pageNumber="7"
        ></app-print-submission-footer>
      </div>
      <div class="page-break"></div>
      <div class="print-layout">
        <ol class="print-numbered-heading-1">
          <li class="print-numbered-heading-1-item">
            {{ fparReportData.fparTables[7].tableName }}
          </li>
          <li class="print-numbered-heading-1-item-title">
            Unduplicated Number of Male Family Planning Users by Primary Method
            and Age Group
          </li>
        </ol>

        <table class="print-table">
          <colgroup>
            <col style="width: 68px" />
            <col style="width: 54px" />
            <col />
            <col />
            <col />
            <col />
            <col />
            <col />
            <col />
            <col />
            <col />
            <col />
          </colgroup>
          <thead>
            <tr>
              <th></th>
              <th></th>
              <th scope="col">Under 15</th>
              <th scope="col">15 to 17</th>
              <th scope="col">18 to 19</th>
              <th scope="col">20 to 24</th>
              <th scope="col">25 to 29</th>
              <th scope="col">30 to 34</th>
              <th scope="col">35 to 39</th>
              <th scope="col">40 to 44</th>
              <th scope="col">Over 44</th>
              <th scope="col">Total</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th rowspan="6" scope="row">Primary Method</th>
            </tr>
            <tr>
              <td>Vasectomy</td>
              <td>{{ fparReportData.fparTables[7].rows[0].ageUnder_15 }}</td>
              <td>{{ fparReportData.fparTables[7].rows[0].age_15To_17 }}</td>
              <td>{{ fparReportData.fparTables[7].rows[0].age_18To_19 }}</td>
              <td>{{ fparReportData.fparTables[7].rows[0].age_20To_24 }}</td>
              <td>{{ fparReportData.fparTables[7].rows[0].age_25To_29 }}</td>
              <td>{{ fparReportData.fparTables[7].rows[0].age_30To_34 }}</td>
              <td>{{ fparReportData.fparTables[7].rows[0].age_35To_39 }}</td>
              <td>{{ fparReportData.fparTables[7].rows[0].age_40To_44 }}</td>
              <td>{{ fparReportData.fparTables[7].rows[0].ageOver_44 }}</td>
              <td>{{ rowTotals[7][0] }}</td>
            </tr>
            <tr>
              <td>Male condom</td>
              <td>{{ fparReportData.fparTables[7].rows[1].ageUnder_15 }}</td>
              <td>{{ fparReportData.fparTables[7].rows[1].age_15To_17 }}</td>
              <td>{{ fparReportData.fparTables[7].rows[1].age_18To_19 }}</td>
              <td>{{ fparReportData.fparTables[7].rows[1].age_20To_24 }}</td>
              <td>{{ fparReportData.fparTables[7].rows[1].age_25To_29 }}</td>
              <td>{{ fparReportData.fparTables[7].rows[1].age_30To_34 }}</td>
              <td>{{ fparReportData.fparTables[7].rows[1].age_35To_39 }}</td>
              <td>{{ fparReportData.fparTables[7].rows[1].age_40To_44 }}</td>
              <td>{{ fparReportData.fparTables[7].rows[1].ageOver_44 }}</td>
              <td>{{ rowTotals[7][1] }}</td>
            </tr>
            <tr>
              <td>FAM</td>
              <td>{{ fparReportData.fparTables[7].rows[2].ageUnder_15 }}</td>
              <td>{{ fparReportData.fparTables[7].rows[2].age_15To_17 }}</td>
              <td>{{ fparReportData.fparTables[7].rows[2].age_18To_19 }}</td>
              <td>{{ fparReportData.fparTables[7].rows[2].age_20To_24 }}</td>
              <td>{{ fparReportData.fparTables[7].rows[2].age_25To_29 }}</td>
              <td>{{ fparReportData.fparTables[7].rows[2].age_30To_34 }}</td>
              <td>{{ fparReportData.fparTables[7].rows[2].age_35To_39 }}</td>
              <td>{{ fparReportData.fparTables[7].rows[2].age_40To_44 }}</td>
              <td>{{ fparReportData.fparTables[7].rows[2].ageOver_44 }}</td>
              <td>{{ rowTotals[7][2] }}</td>
            </tr>
            <tr>
              <td>Abstinence</td>
              <td>{{ fparReportData.fparTables[7].rows[3].ageUnder_15 }}</td>
              <td>{{ fparReportData.fparTables[7].rows[3].age_15To_17 }}</td>
              <td>{{ fparReportData.fparTables[7].rows[3].age_18To_19 }}</td>
              <td>{{ fparReportData.fparTables[7].rows[3].age_20To_24 }}</td>
              <td>{{ fparReportData.fparTables[7].rows[3].age_25To_29 }}</td>
              <td>{{ fparReportData.fparTables[7].rows[3].age_30To_34 }}</td>
              <td>{{ fparReportData.fparTables[7].rows[3].age_35To_39 }}</td>
              <td>{{ fparReportData.fparTables[7].rows[3].age_40To_44 }}</td>
              <td>{{ fparReportData.fparTables[7].rows[3].ageOver_44 }}</td>
              <td>{{ rowTotals[7][3] }}</td>
            </tr>
            <tr>
              <td>Withdrawal or other method</td>
              <td>{{ fparReportData.fparTables[7].rows[4].ageUnder_15 }}</td>
              <td>{{ fparReportData.fparTables[7].rows[4].age_15To_17 }}</td>
              <td>{{ fparReportData.fparTables[7].rows[4].age_18To_19 }}</td>
              <td>{{ fparReportData.fparTables[7].rows[4].age_20To_24 }}</td>
              <td>{{ fparReportData.fparTables[7].rows[4].age_25To_29 }}</td>
              <td>{{ fparReportData.fparTables[7].rows[4].age_30To_34 }}</td>
              <td>{{ fparReportData.fparTables[7].rows[4].age_35To_39 }}</td>
              <td>{{ fparReportData.fparTables[7].rows[4].age_40To_44 }}</td>
              <td>{{ fparReportData.fparTables[7].rows[4].ageOver_44 }}</td>
              <td>{{ rowTotals[7][4] }}</td>
            </tr>
            <tr>
              <th colspan="2" scope="row">Rely on Female Method</th>
              <td>{{ fparReportData.fparTables[7].rows[5].ageUnder_15 }}</td>
              <td>{{ fparReportData.fparTables[7].rows[5].age_15To_17 }}</td>
              <td>{{ fparReportData.fparTables[7].rows[5].age_18To_19 }}</td>
              <td>{{ fparReportData.fparTables[7].rows[5].age_20To_24 }}</td>
              <td>{{ fparReportData.fparTables[7].rows[5].age_25To_29 }}</td>
              <td>{{ fparReportData.fparTables[7].rows[5].age_30To_34 }}</td>
              <td>{{ fparReportData.fparTables[7].rows[5].age_35To_39 }}</td>
              <td>{{ fparReportData.fparTables[7].rows[5].age_40To_44 }}</td>
              <td>{{ fparReportData.fparTables[7].rows[5].ageOver_44 }}</td>
              <td>{{ rowTotals[7][5] }}</td>
            </tr>
            <tr>
              <th rowspan="3" scope="row">No Method</th>
            </tr>
            <tr>
              <td>Pregnant/seeking pregnancy</td>
              <td>{{ fparReportData.fparTables[7].rows[6].ageUnder_15 }}</td>
              <td>{{ fparReportData.fparTables[7].rows[6].age_15To_17 }}</td>
              <td>{{ fparReportData.fparTables[7].rows[6].age_18To_19 }}</td>
              <td>{{ fparReportData.fparTables[7].rows[6].age_20To_24 }}</td>
              <td>{{ fparReportData.fparTables[7].rows[6].age_25To_29 }}</td>
              <td>{{ fparReportData.fparTables[7].rows[6].age_30To_34 }}</td>
              <td>{{ fparReportData.fparTables[7].rows[6].age_35To_39 }}</td>
              <td>{{ fparReportData.fparTables[7].rows[6].age_40To_44 }}</td>
              <td>{{ fparReportData.fparTables[7].rows[6].ageOver_44 }}</td>
              <td>{{ rowTotals[7][6] }}</td>
            </tr>
            <tr>
              <td>Other reason</td>
              <td>{{ fparReportData.fparTables[7].rows[7].ageUnder_15 }}</td>
              <td>{{ fparReportData.fparTables[7].rows[7].age_15To_17 }}</td>
              <td>{{ fparReportData.fparTables[7].rows[7].age_18To_19 }}</td>
              <td>{{ fparReportData.fparTables[7].rows[7].age_20To_24 }}</td>
              <td>{{ fparReportData.fparTables[7].rows[7].age_25To_29 }}</td>
              <td>{{ fparReportData.fparTables[7].rows[7].age_30To_34 }}</td>
              <td>{{ fparReportData.fparTables[7].rows[7].age_35To_39 }}</td>
              <td>{{ fparReportData.fparTables[7].rows[7].age_40To_44 }}</td>
              <td>{{ fparReportData.fparTables[7].rows[7].ageOver_44 }}</td>
              <td>{{ rowTotals[7][7] }}</td>
            </tr>
            <tr>
              <th colspan="2" scope="row">Unknown/Not Reported</th>
              <td>{{ fparReportData.fparTables[7].rows[8].ageUnder_15 }}</td>
              <td>{{ fparReportData.fparTables[7].rows[8].age_15To_17 }}</td>
              <td>{{ fparReportData.fparTables[7].rows[8].age_18To_19 }}</td>
              <td>{{ fparReportData.fparTables[7].rows[8].age_20To_24 }}</td>
              <td>{{ fparReportData.fparTables[7].rows[8].age_25To_29 }}</td>
              <td>{{ fparReportData.fparTables[7].rows[8].age_30To_34 }}</td>
              <td>{{ fparReportData.fparTables[7].rows[8].age_35To_39 }}</td>
              <td>{{ fparReportData.fparTables[7].rows[8].age_40To_44 }}</td>
              <td>{{ fparReportData.fparTables[7].rows[8].ageOver_44 }}</td>
              <td>{{ rowTotals[7][8] }}</td>
            </tr>
            <tr>
              <th>Total</th>
              <td></td>
              <td style="font-weight: bold; text-align: right">
                {{ columnTotals[20] }}
              </td>
              <td style="font-weight: bold; text-align: right">
                {{ columnTotals[21] }}
              </td>
              <td style="font-weight: bold; text-align: right">
                {{ columnTotals[22] }}
              </td>
              <td style="font-weight: bold; text-align: right">
                {{ columnTotals[23] }}
              </td>
              <td style="font-weight: bold; text-align: right">
                {{ columnTotals[24] }}
              </td>
              <td style="font-weight: bold; text-align: right">
                {{ columnTotals[25] }}
              </td>
              <td style="font-weight: bold; text-align: right">
                {{ columnTotals[26] }}
              </td>
              <td style="font-weight: bold; text-align: right">
                {{ columnTotals[27] }}
              </td>
              <td style="font-weight: bold; text-align: right">
                {{ columnTotals[28] }}
              </td>
              <td style="font-weight: bold; text-align: right">
                {{ totalTotals[7] }}
              </td>
            </tr>
          </tbody>
        </table>
        <app-print-submission-footer
          class="print-footer"
          [year]="dataReportingPeriodYear"
          [granteeName]="fparReportData.granteeName"
          pageNumber="8"
        ></app-print-submission-footer>
      </div>
      <div class="page-break"></div>
      <div class="print-layout">
        <ol class="print-numbered-heading-1">
          <li class="print-numbered-heading-1-item">
            {{ fparReportData.fparTables[8].tableName }}
          </li>
          <li class="print-numbered-heading-1-item-title">
            Cervical Cancer Screening Activities
          </li>
        </ol>

        <table class="print-table">
          <thead>
            <tr>
              <th></th>
              <th></th>
              <th scope="col">Total</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th rowspan="5" scope="row">Screening Activity</th>
            </tr>
            <tr>
              <td>
                Unduplicated number of female users who obtained a Pap test
              </td>
              <td>{{ fparReportData.fparTables[8].rows[0].value }}</td>
            </tr>
            <tr>
              <td>Number of Pap tests performed</td>
              <td>{{ fparReportData.fparTables[8].rows[1].value }}</td>
            </tr>
            <tr>
              <td>Number of Pap tests with an ASC or higher result</td>
              <td>{{ fparReportData.fparTables[8].rows[2].value }}</td>
            </tr>
            <tr>
              <td>Number of Pap tests with an HSIL or higher result</td>
              <td>{{ fparReportData.fparTables[8].rows[3].value }}</td>
            </tr>
            <tr>
              <th>Total</th>
              <td></td>
              <td style="font-weight: bold; text-align: right">
                {{ totalTotals[8] }}
              </td>
            </tr>
          </tbody>
        </table>
        <app-print-submission-footer
          class="print-footer"
          [year]="dataReportingPeriodYear"
          [granteeName]="fparReportData.granteeName"
          pageNumber="9"
        ></app-print-submission-footer>
      </div>
      <div class="page-break"></div>
      <div class="print-layout">
        <ol class="print-numbered-heading-1">
          <li class="print-numbered-heading-1-item"></li>
          <li class="print-numbered-heading-1-item-title">
            Clinical Breast Exams and Referrals
          </li>
        </ol>

        <table class="print-table">
          <thead>
            <tr>
              <th></th>
              <th></th>
              <th scope="col">Total</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th rowspan="3" scope="row">Screening Activity</th>
            </tr>
            <tr>
              <td>
                Unduplicated number of female users who received a clinical
                breast exam (CBE)
              </td>
            </tr>
            <tr>
              <td>
                Unduplicated number of female users referred for further
                evaluation based on their CBE
              </td>
            </tr>
            <tr>
              <th>Total</th>
              <td></td>
            </tr>
          </tbody>
        </table>
        <app-print-submission-footer
          class="print-footer"
          [year]="dataReportingPeriodYear"
          [granteeName]="fparReportData.granteeName"
          pageNumber="10"
        ></app-print-submission-footer>
      </div>
      <div class="page-break"></div>
      <div class="print-layout">
        <ol class="print-numbered-heading-1">
          <li class="print-numbered-heading-1-item">
            {{ fparReportData.fparTables[9].tableName }}
          </li>
          <li class="print-numbered-heading-1-item-title">
            Unduplicated Number of Family Planning Users Tested for Chlamydia by
            Age Group and Sex
          </li>
        </ol>

        <table class="print-table">
          <thead>
            <tr>
              <th></th>
              <th></th>
              <th scope="col">Female Users</th>
              <th scope="col">Male Users</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th rowspan="6" scope="row" style="font-weight: bold">
                Age Group
              </th>
            </tr>
            <tr>
              <td>Under 15</td>
              <td>{{ fparReportData.fparTables[9].rows[0].female }}</td>
              <td>{{ fparReportData.fparTables[9].rows[0].male }}</td>
            </tr>
            <tr>
              <td>15 to 17</td>
              <td>{{ fparReportData.fparTables[9].rows[1].female }}</td>
              <td>{{ fparReportData.fparTables[9].rows[1].male }}</td>
            </tr>

            <tr>
              <td>18 to 19</td>
              <td>{{ fparReportData.fparTables[9].rows[2].female }}</td>
              <td>{{ fparReportData.fparTables[9].rows[2].male }}</td>
            </tr>
            <tr>
              <td>20 to 24</td>
              <td>{{ fparReportData.fparTables[9].rows[3].female }}</td>
              <td>{{ fparReportData.fparTables[9].rows[3].male }}</td>
            </tr>
            <tr>
              <td>25 and over</td>
              <td>{{ fparReportData.fparTables[9].rows[4].female }}</td>
              <td>{{ fparReportData.fparTables[9].rows[4].male }}</td>
            </tr>
            <tr>
              <th>Total</th>
              <td></td>
              <td style="font-weight: bold; text-align: right">
                {{ columnTotals[30] }}
              </td>
              <td style="font-weight: bold; text-align: right">
                {{ columnTotals[31] }}
              </td>
            </tr>
          </tbody>
        </table>
        <app-print-submission-footer
          class="print-footer"
          [year]="dataReportingPeriodYear"
          [granteeName]="fparReportData.granteeName"
          pageNumber="11"
        ></app-print-submission-footer>
      </div>
      <div class="page-break"></div>
      <div class="print-layout">
        <ol class="print-numbered-heading-1">
          <li class="print-numbered-heading-1-item">
            {{ fparReportData.fparTables[10].tableName }}
          </li>
          <li class="print-numbered-heading-1-item-title">
            Number of Tests for Gonorrhea, Syphilis, and HIV and Number of
            Positive Confidential HIV Tests
          </li>
        </ol>

        <table class="print-table">
          <thead>
            <tr>
              <th></th>
              <th></th>
              <th scope="col" style="text-align: right">Female Users</th>
              <th scope="col" style="text-align: right">Male Users</th>
              <th scope="col" style="text-align: right">Total</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th rowspan="6" scope="row" style="font-weight: bold">
                Test Type
              </th>
            </tr>
            <tr>
              <td>Gonorrhea</td>
              <td>{{ fparReportData.fparTables[10].rows[0].female }}</td>
              <td>{{ fparReportData.fparTables[10].rows[0].male }}</td>
              <td>{{ rowTotals[10][0] }}</td>
            </tr>
            <tr>
              <td>Syphilis</td>
              <td>{{ fparReportData.fparTables[10].rows[1].female }}</td>
              <td>{{ fparReportData.fparTables[10].rows[1].male }}</td>
              <td>{{ rowTotals[10][1] }}</td>
            </tr>

            <tr>
              <td>HIV – All confidential tests</td>
              <td>{{ fparReportData.fparTables[10].rows[2].female }}</td>
              <td>{{ fparReportData.fparTables[10].rows[2].male }}</td>
              <td>{{ rowTotals[10][2] }}</td>
            </tr>
            <tr>
              <td>HIV – Positive confidential tests</td>

              <td colspan="2" style="background-color: lightgray"></td>
              <td>{{ rowTotals[10][3] }}</td>
            </tr>
            <tr>
              <td>HIV – Anonymous tests</td>

              <td colspan="2" style="background-color: lightgray"></td>
              <td>{{ rowTotals[10][4] }}</td>
            </tr>
            <tr>
              <th>Total</th>
              <td></td>
              <td></td>
              <td></td>
              <td style="font-weight: bold; text-align: right">
                {{ totalTotals[10] }}
              </td>
            </tr>
          </tbody>
        </table>
        <app-print-submission-footer
          class="print-footer"
          [year]="dataReportingPeriodYear"
          [granteeName]="fparReportData.granteeName"
          pageNumber="12"
        ></app-print-submission-footer>
      </div>
      <div class="page-break"></div>
      <div class="print-layout">
        <ol class="print-numbered-heading-1">
          <li class="print-numbered-heading-1-item">
            {{ fparReportData.fparTables[11].tableName }}
          </li>
          <li class="print-numbered-heading-1-item-title">
            Number of Full-Time Equivalent Clinical Services Providers and
            Family Planning Encounters by Type of Provider
          </li>
        </ol>

        <table class="print-table">
          <thead>
            <tr>
              <th></th>
              <th></th>
              <th></th>
              <th scope="col" style="text-align: right">Number of FTEs</th>
              <th scope="col" style="text-align: right">
                Number of Family Planning Encounters
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th
                rowspan="6"
                scope="row"
                style="font-weight: bold; text-transform: uppercase"
              >
                Provider Type
              </th>
            </tr>
            <tr>
              <th rowspan="4" scope="row">Clinical Services Providers</th>
              <td></td>
              <td></td>
              <td style="text-align: right">
                {{ removeDecimal(fparReportData.fparTables[11].rows[0].fpe) }}
              </td>
            </tr>
            <tr>
              <td>Physicians</td>
              <td style="text-align: right">
                {{ removeDecimal(fparReportData.fparTables[11].rows[1].fte) }}
              </td>
              <td rowspan="3"></td>
            </tr>
            <tr>
              <td>
                Physician assistants/nurse practitioners/ certified nurse
                midwives
              </td>
              <td style="text-align: right">
                {{ removeDecimal(fparReportData.fparTables[11].rows[2].fte) }}
              </td>
            </tr>
            <tr>
              <td>
                Registered nurses with an expanded scope of practice who are
                trained and permitted by state-specific regulations to perform
                all aspects of the user physical assessment
              </td>
              <td style="text-align: right">
                {{ removeDecimal(fparReportData.fparTables[11].rows[3].fte) }}
              </td>
            </tr>
            <tr>
              <th>Other Services Providers</th>
              <td></td>
              <td></td>
              <td style="text-align: right">
                {{ fparReportData.fparTables[11].rows[4].fpe }}
              </td>
            </tr>
            <tr>
              <th>Total</th>
              <td colspan="4" style="font-weight: bold; text-align: right">
                {{ columnTotals[36] }}
              </td>
            </tr>
          </tbody>
        </table>
        <app-print-submission-footer
          class="print-footer"
          [year]="dataReportingPeriodYear"
          [granteeName]="fparReportData.granteeName"
          pageNumber="13"
        ></app-print-submission-footer>
      </div>
      <div class="page-break"></div>
      <div class="print-layout">
        <ol class="print-numbered-heading-1">
          <li class="print-numbered-heading-1-item">
            {{ fparReportData.fparTables[12].tableName }}
          </li>
          <li class="print-numbered-heading-1-item-title">Revenue Report</li>
        </ol>

        <table class="print-table">
          <thead>
            <tr>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th
                [attr.rowspan]="32 + otherRevenueRows.length"
                scope="row"
                style="font-weight: bold; text-transform: uppercase"
              >
                Revenue Source
              </th>
            </tr>
            <tr>
              <th colspan="4" scope="row">
                Title X grant (Section 1001: family planning services)
              </th>
              <td>
                {{
                  removeDecimal(fparReportData.fparTables[12].rows[0].amount)
                }}
              </td>
            </tr>
            <tr>
              <th rowspan="21">Payment for Services</th>
            </tr>
            <tr>
              <td colspan="3">Total client collections/self-pay</td>
              <td>
                {{
                  removeDecimal(fparReportData.fparTables[12].rows[1].amount)
                }}
              </td>
            </tr>
            <tr>
              <td rowspan="16">Third-party payers</td>
            </tr>
            <tr>
              <td rowspan="3">Medicaid (Title XIX)</td>
            </tr>
            <tr>
              <td>Amount Prepaid</td>
              <td style="text-align: right">
                {{
                  removeDecimal(fparReportData.fparTables[12].rows[2].prepaid)
                }}
              </td>
            </tr>
            <tr>
              <td>Amount Not Prepaid</td>
              <td>
                {{
                  removeDecimal(
                    fparReportData.fparTables[12].rows[2].notPrepaid
                  )
                }}
              </td>
            </tr>
            <tr>
              <td rowspan="3">Medicare (Title XVIII)</td>
            </tr>
            <tr>
              <td>Amount Prepaid</td>
              <td style="text-align: right">
                {{
                  removeDecimal(fparReportData.fparTables[12].rows[3].prepaid)
                }}
              </td>
            </tr>
            <tr>
              <td>Amount Not Prepaid</td>
              <td>
                {{
                  removeDecimal(
                    fparReportData.fparTables[12].rows[3].notPrepaid
                  )
                }}
              </td>
            </tr>

            <tr>
              <td rowspan="3">Children’s Health Insurance Program (CHIP)</td>
            </tr>
            <tr>
              <td>Amount Prepaid</td>
              <td style="text-align: right">
                {{
                  removeDecimal(fparReportData.fparTables[12].rows[4].prepaid)
                }}
              </td>
            </tr>
            <tr>
              <td>Amount Not Prepaid</td>
              <td>
                {{
                  removeDecimal(
                    fparReportData.fparTables[12].rows[4].notPrepaid
                  )
                }}
              </td>
            </tr>
            <tr>
              <td rowspan="3">Other public health insurance</td>
            </tr>
            <tr>
              <td>Amount Prepaid</td>
              <td style="text-align: right">
                {{
                  removeDecimal(fparReportData.fparTables[12].rows[5].prepaid)
                }}
              </td>
            </tr>
            <tr>
              <td>Amount Not Prepaid</td>
              <td>
                {{
                  removeDecimal(
                    fparReportData.fparTables[12].rows[5].notPrepaid
                  )
                }}
              </td>
            </tr>
            <tr>
              <td rowspan="3">Private health insurance</td>
            </tr>
            <tr>
              <td>Amount Prepaid</td>
              <td style="text-align: right">
                {{
                  removeDecimal(fparReportData.fparTables[12].rows[6].prepaid)
                }}
              </td>
            </tr>
            <tr>
              <td>Amount Not Prepaid</td>
              <td>
                {{
                  removeDecimal(
                    fparReportData.fparTables[12].rows[6].notPrepaid
                  )
                }}
              </td>
            </tr>
            <tr>
              <td rowspan="3" colspan="2">Total – Third-Party Payers</td>
            </tr>
            <tr>
              <td>Amount Prepaid</td>
              <td style="text-align: right">
                {{ removeDecimal(thirdPartyRevenueTotals.prepaid) }}
              </td>
            </tr>
            <tr>
              <td>Amount Not Prepaid</td>
              <td>
                {{ removeDecimal(thirdPartyRevenueTotals.notPrepaid) }}
              </td>
            </tr>
            <tr>
              <th colspan="4">Total - Payment for Services</th>
              <td style="font-weight: bold">{{ totalPaymentForServices }}</td>
            </tr>
            <tr>
              <th [attr.rowspan]="7 + otherRevenueRows.length">
                Other Revenue
              </th>
            </tr>
            <tr>
              <td colspan="3">Title V (MCH Block Grant)</td>
              <td>
                {{
                  removeDecimal(fparReportData.fparTables[12].rows[7].amount)
                }}
              </td>
            </tr>
            <tr>
              <td colspan="3">Title XX (Social Services Block Grant)</td>
              <td>
                {{
                  removeDecimal(fparReportData.fparTables[12].rows[8].amount)
                }}
              </td>
            </tr>
            <tr>
              <td colspan="3">
                Temporary Assistance for Needy Families (TANF)
              </td>
              <td>
                {{
                  removeDecimal(fparReportData.fparTables[12].rows[9].amount)
                }}
              </td>
            </tr>
            <tr>
              <td colspan="3">Local government revenue</td>
              <td>
                {{
                  removeDecimal(fparReportData.fparTables[12].rows[10].amount)
                }}
              </td>
            </tr>
            <tr>
              <td colspan="3">State government revenue</td>
              <td>
                {{
                  removeDecimal(fparReportData.fparTables[12].rows[11].amount)
                }}
              </td>
            </tr>
            <tr>
              <td colspan="3">Bureau of Primary Health Care (BPHC)</td>
              <td>
                {{
                  removeDecimal(fparReportData.fparTables[12].rows[12].amount)
                }}
              </td>
            </tr>
            <ng-container *ngFor="let item of otherRevenueRows">
              <tr>
                <td colspan="3">{{ item.rowLabel }}</td>
                <td>{{ item.amount }}</td>
              </tr>
            </ng-container>
            <tr>
              <th colspan="4">Total - Other Revenue</th>
              <td style="font-weight: bold">{{ otherRevenueTotal }}</td>
            </tr>
            <tr>
              <th colspan="5" scope="row" style="text-transform: uppercase">
                Total Revenue
              </th>
              <td style="font-weight: bold">{{ revenueTotal }}</td>
            </tr>
          </tbody>
        </table>
        <app-print-submission-footer
          class="print-footer"
          [year]="dataReportingPeriodYear"
          [granteeName]="fparReportData.granteeName"
          pageNumber="14"
        ></app-print-submission-footer>
      </div>
      <div class="page-break"></div>
      <div class="print-layout">
        <img
          src="../../../../assets/oash-logo-bw.svg"
          class="print-logo"
          alt="OASH Office of Population Affairs logo"
        />
        <div class="print-title">
          <p class="print-title-intro">
            {{ dataReportingPeriodYear }} FPAR<br />
            Reporting Period<br />
            Summary for
          </p>
          <p class="print-title-grant">{{ fparReportData.granteeName }}</p>
        </div>
        <div class="print-project-director">
          <p class="print-project-director-label">Title X Project Director</p>
          <p class="print-project-director-name">
            {{ grantCoverSheetContact.grContactFirst }}
            {{ grantCoverSheetContact.grContactLast }}
          </p>
        </div>
        <div class="print-contact-person">
          <p class="print-contact-person-label">Grantee Contact Person</p>
          <p class="print-contact-person-name">
            {{ grantCoverSheetDirector.grProjDirFirst }}
            {{ grantCoverSheetDirector.grProjDirLast }}
          </p>
        </div>
      </div>
      <div class="page-break"></div>
      <div class="appendix">
        <ol class="print-numbered-heading-1">
          <li class="print-numbered-heading-1-item">Appendix</li>
        </ol>

        <div class="appendix-comments">
          <div class="print-submission-comments">
            <p class="print-notes-heading">Submission Comments</p>
            <p
              *ngIf="fparReportData.submissionComment"
              class="print-submission-comment"
            >
              {{ fparReportData.submissionComment.comment }} -
              <em class="appendix-comment-metadata">
                {{ fparReportData.submissionComment.nameFirst }}
                {{ fparReportData.submissionComment.nameLast }} -
                {{ fparReportData.submissionComment.lastUpdated | date }}
              </em>
            </p>
          </div>
          <div class="print-notes">
            <p class="print-notes-heading">Table Comments</p>
            <div
              class="no-page-break-between"
              *ngFor="let table of fparReportData.fparTables; let index"
            >
              <strong *ngIf="table.comments.length > 0">{{
                table.tableName
              }}</strong>
              <p
                class="print-submission-comment"
                *ngFor="let comment of table.comments"
              >
                {{ comment.comment }} -
                <em class="appendix-comment-metadata">
                  {{ comment.author }} - {{ comment.addedDate | date }}
                  <span *ngIf="comment.addedDate !== comment.updatedDate"
                    >(edited {{ comment.updatedDate | date }})</span
                  >
                </em>
              </p>
            </div>
          </div>
        </div>
        <app-print-submission-footer
          class="print-footer appendix-footer"
          [year]="dataReportingPeriodYear"
          [granteeName]="fparReportData.granteeName"
          pageNumber="15"
        ></app-print-submission-footer>
      </div>
    </div>
  </div>
</ng-container>
